import * as React from "react";
import { Gathering } from "../types/gathering";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function GatheringTile(gathering: Gathering) {
  const image = getImage(gathering.logo);

  console.log("GatheringTile", gathering);

  return (
    <div
      key={gathering.slug}
      className="max-w-[80vw] sm:max-w-xs flex-shrink-0 rounded-lg overflow-clip bg-slate-950 px-4 py-3 group hover:bg-slate-700 hover:cursor-pointer transition ease-in-out delay-50 duration-500"
    >
      <Link
        to={gathering.slug}
        className="h-full flex flex-col justify-between"
      >
        <div className="flex-grow flex flex-col justify-center">
          {image ? (
            <GatsbyImage image={image} alt={gathering.name} />
          ) : (
            <img
              className="rounded-lg overflow-clip w-full max-w-prose mx-auto"
              src={gathering.logo.publicURL}
              alt={gathering.name}
            />
          )}
        </div>
        <div className="grow-0 text-center">{gathering.name}</div>
      </Link>
    </div>
  );
}

export default GatheringTile;
