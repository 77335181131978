import * as React from "react";
import { graphql } from "gatsby";
import TalkTile from "../components/talk-tile";
import ConferenceAdTile from "../components/conference-ad-tile";
import { StaticImage } from "gatsby-plugin-image";
import { SEO } from "../components/seo";
import conferenceAds from "../../private-data/conference-ads/conference-ads.json";
import GatheringTile from "../components/gathering-tile";

export const Head = () => <SEO title="async TechConnection" />;

const IndexPage = ({
  data: {
    allEventsJson: { nodes: allEventsNodes },
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => (
      <TalkTile key={edge.node.id} post={edge.node} eventPageMode={false} />
    ));

  const conferences = conferenceAds.map((conferenceAd) => (
    <ConferenceAdTile {...conferenceAd} />
  ));

  const gatherings = allEventsNodes.map((event) => (
    <GatheringTile key={event.slug} {...event} />
  ));

  return (
    <>
      <div className="fixed h-full w-full gradient bg-gradient-to-tr from-slate-900 from-70% to-slate-800 -z-10">
        &nbsp;
      </div>
      <div className="leading-normal tracking-normal text-white bg-transparent">
        <nav
          id="header"
          className="fixed w-full z-30 top-0 text-white bg-black/30"
        >
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
            <div className="pl-4 flex items-center">
              <a
                className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <code>async</code>.TechConnection
              </a>
            </div>
          </div>
        </nav>
        <div className="pt-24 mb-24">
          <div className="container px-3 mx-auto h-96 mb-8 text-center grid">
            <div className="place-self-center max-w-[90vw]">
              <StaticImage
                className="mx-auto max-w-[90vw] md:max-w-full"
                layout="fixed"
                height={200}
                src="../../static/tech-connection-logo.svg"
                alt="TechConnection Logo"
              />
            </div>
          </div>
          <div className="container mx-auto px-4 mb-8">
            <h2 className="text-2xl font-bold mb-2">Upcoming Conferences</h2>
            <div className="flex overflow-x-auto gap-4">{conferences}</div>
          </div>
          <div className="container mx-auto px-4 mb-8">
            <h2 className="text-2xl font-bold mb-2">Our Gatherings</h2>
            <div className="flex overflow-x-auto gap-4">{gatherings}</div>
          </div>
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-2">All videos</h2>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 grid-flow-row">
              {posts}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allEventsJson {
      nodes {
        slug
        name
        logo {
          childImageSharp {
            gatsbyImageData(quality: 99)
          }
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          thumbnailImage {
            childImageSharp {
              gatsbyImageData(quality: 99)
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            tags
            video
            conference
            edition
          }
        }
      }
    }
  }
`;
